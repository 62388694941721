import {useCallback, type ReactNode} from 'react';
import {Shell} from '@halp/ui';
import {useUser} from '@halp/foundation/Users';
import {SideNav} from './SideNav';
import style from './AppShell.module.css';

export function AppShell({children}: {children: ReactNode}) {
	const {user} = useUser();

	const nav = useCallback(
		(_mobile: boolean, setOpenMenu: (open: boolean) => void) => {
			return <SideNav setOpenMenu={setOpenMenu} />;
		},
		[],
	);

	return (
		<Shell
			flexNav={false}
			sideNav={nav}
			hideTopSideNav={user == null}
			contentClassName={style.AppContentAdmin}
		>
			{children}
		</Shell>
	);
}
